export const Performance = {
    props: ['player_data'],
    data() {
        return {
            // player_data: [],
        };
    },
    methods: {


        timesOut(player_id, innings) {


            for (let c = 0; c < innings.length; c++) {

                if (innings[c].player_id === player_id) {

                    if (innings[c].time_out === 0) {
                        return 1
                    } else {
                        return innings[c].time_out;
                    }

                }
            }

        },

        countInnings(player_id, innings) {

            for (let i = 0; i < innings.length; i++) {
                if (innings[i].player_id === player_id) {
                    return innings[i].innings;
                }
            }

            return 0;
        },

        countMatches(player_id, matches) {

            for (let i = 0; i < matches.length; i++) {

                if (matches[i].player_id === player_id) {
                    return matches[i].matches;
                }

            }

            return 0;
        },

        matches(player_id) {

            let matches = this.player_data.player_matches;

            for (let i = 0; i < matches.length; i++) {

                if (matches[i].player_id === player_id) {

                    if (matches[i].matches > 0) {
                        return matches[i].matches;
                    } else {
                        return 0;
                    }


                }

            }

            return '-';

        },

        innings(player_id) {

            let innings = this.player_data.player_innings;

            for (let i = 0; i < innings.length; i++) {

                if (innings[i].player_id === player_id) {

                    if (innings[i].innings > 0) {

                        return innings[i].innings;

                    }


                }


            }
            return '-';

        },

        outs(player_id) {

            let innings = this.player_data.player_innings;

            for (let i = 0; i < innings.length; i++) {

                if (innings[i].player_id === player_id) {

                    if (innings[i].time_out > 0) {

                        return innings[i].time_out;

                    } else {
                        return 0;
                    }


                }

            }
            return '-';

        },

        runs(player_id) {

            let player_scores = this.player_data.player_scores;

            for (let i = 0; i < player_scores.length; i++) {

                if (player_scores[i].player_id === player_id) {


                    return player_scores[i].score;

                }

            }

            return '-';

        },

        highScores(player_id) {

            let player_high_scores = this.player_data.player_high_scores;

            for (let i = 0; i < player_high_scores.length; i++) {

                if (player_high_scores[i].player_id === player_id) {


                    return player_high_scores[i].score;


                }

            }

            return '-';

        },

        bf(player_id) {

            let player_bf = this.player_data.player_bf;

            for (let i = 0; i < player_bf.length; i++) {

                if (player_bf[i].player_id === player_id) {


                    return player_bf[i].bf;


                }

            }

            return '-';

        },

        fhundred(player_id) {

            let player_hundred = this.player_data.player_hundred;

            for (let i = 0; i < player_hundred.length; i++) {

                if (player_hundred[i].player_id === player_id) {

                    if (player_hundred[i].hundred > 0) {
                        return player_hundred[i].hundred;
                    }

                    return '-'

                }

            }

            return '-';
        },

        fifty(player_id) {
            let player_fifty = this.player_data.player_fifty;

            for (let i = 0; i < player_fifty.length; i++) {

                if (player_fifty[i].player_id === player_id) {

                    if (player_fifty[i].fifty > 0) {
                        return player_fifty[i].fifty;
                    }

                    return '-'

                }

            }

            return '-';
        },

        four(player_id) {
            let player_four = this.player_data.player_four;

            for (let i = 0; i < player_four.length; i++) {

                if (player_four[i].player_id === player_id) {

                    if (player_four[i].four > 0) {
                        return player_four[i].four;
                    }

                    return '-'

                }

            }

            return '-';
        },

        six(player_id) {
            let player_six = this.player_data.player_six;

            for (let i = 0; i < player_six.length; i++) {

                if (player_six[i].player_id === player_id) {

                    if (player_six[i].six > 0) {
                        return player_six[i].six;
                    }

                    return '-'

                }

            }

            return '-';
        },

        caught(player_id) {
            let player_ct = this.player_data.player_cts;

            for (let i = 0; i < player_ct.length; i++) {

                if (player_ct[i].player_id === player_id) {

                    if (player_ct[i].six > 0) {
                        return player_ct[i].ct;
                    }

                    return '-'

                }

            }

            return '-';
        },
        stump(player_id) {
            let player_st = this.player_data.player_sts;

            for (let i = 0; i < player_st.length; i++) {

                if (player_st[i].player_id === player_id) {

                    if (player_st[i].six > 0) {
                        return player_st[i].st;
                    }

                    return '-'

                }

            }

            return '-';
        },
        balls(player_id) {

            let balls = this.player_data.player_bowls;

            for (let i = 0; i < balls.length; i++) {

                if (balls[i].player_id === player_id) {

                    if (balls[i].bowl > 0) {

                        return balls[i].bowl;

                    }


                }


            }

            return '-';

        },
        wickets(player_id) {

            let wickets = this.player_data.player_wickets;

            for (let i = 0; i < wickets.length; i++) {

                if (wickets[i].player_id === player_id) {

                    if (wickets[i].wkts > 0) {

                        return wickets[i].wkts;

                    }


                }


            }

            return '-';

        },
        maxWicketInInning(player_id) {

            let wickets = this.player_data.player_inning_wickets;

            for (let i = 0; i < wickets.length; i++) {

                if (wickets[i].player_id === player_id) {


                    if (wickets[i].wicket) {
                        return {
                            wicket: wickets[i].wicket,
                            inning_id: wickets[i].inning_id
                        };
                    } else {
                        return {
                            wicket: '-',
                            inning_id: wickets[i].inning_id
                        };
                    }


                }


            }

            return {wicket: '-'};

        },

        minRunsInInning(player_id, inning_id) {

            let runs = this.player_data.player_inning_scores;

            for (let i = 0; i < runs.length; i++) {


                if (runs[i].player_id === player_id && runs[i].inning_id === inning_id) {


                    if (runs[i].score) {
                        return runs[i].score;
                    } else {
                        return '-';
                    }


                }


            }

            return '-';

        },

        maxWicketInFixture(player_id) {

            let wickets = this.player_data.player_fixture_wickets;

            for (let i = 0; i < wickets.length; i++) {

                if (wickets[i].player_id === player_id) {


                    // if( wickets[i].wicket === wickets[i + 1].wicket ){
                    //     this.minRunsInFixture()
                    // }

                    if (wickets[i].wicket) {
                        return {
                            wicket: wickets[i].wicket,
                            fixture_id: wickets[i].fixture_id
                        };
                    } else {
                        return {
                            wicket: '-',
                            fixture_id: wickets[i].fixture_id
                        };
                    }


                }


            }

            return {wicket: '-'};

        },

        minRunsInFixture(player_id, fixture_id) {

            let runs = this.player_data.player_fixture_scores;

            for (let i = 0; i < runs.length; i++) {


                if (runs[i].player_id === player_id && runs[i].fixture_id === fixture_id) {


                    if (runs[i].score) {
                        return runs[i].score;
                    } else {
                        return '-';
                    }


                }


            }

            return '-';

        },
        countWicket(player_id) {

            let wicket = this.player_data.player_count_wickets;

            for (let i = 0; i < wicket.length; i++) {


                if (wicket[i].player_id === player_id) {


                    return {
                        four_wicket: wicket[i].four_wicket,
                        five_wicket: wicket[i].five_wicket,
                        ten_wicket: wicket[i].ten_wicket,
                        fourteen_wicket: wicket[i].fourteen_wicket,
                    }


                }


            }

            return '-';

        },

    }
}