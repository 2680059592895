<template>
  <head-slot>
    <title>Report | Top Performers</title>
  </head-slot>
  <div class="row report-params">
    <div class="col-12">
      <div class="card card-primary">
        <div class="card-header">
          <h4>Performance Parameters</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6 col-md-3">
              <div class="form-group">
                <label for="game_format_id">Game Formats</label>
                <select id="game_format_id" class="form-control"
                        v-model="selectionCriteria.game_format_id"
                        @change="loadTournaments"
                >
                  <option value="">--Select--</option>
                  <option v-for="(gameFormat,index) in gameFormats" :key="index"
                          :value="gameFormat.id"
                          @select="alert();"
                  >
                    {{ gameFormat.name }}

                  </option>

                </select>
              </div>
            </div>

            <div class="col-6 col-md-3">
              <div class="form-group">
                <label for="playing_roll_id">Playing Roll</label>
                <select id="playing_roll_id" class="form-control" v-model="selectionCriteria.playing_roll_id"
                        @change="prChange">
                  <option value="">--Select--</option>
                  <option v-for="(playingRoll, index) in playingRolls" :key="index"
                          :value="playingRoll.id">
                    {{ playingRoll.name }}
                  </option>

                </select>
              </div>
            </div>

            <div class="col-6 col-md-3" v-if="selectionCriteria.playing_roll_id === 1">
              <div class="form-group">
                <label>Batting Style</label>
                <select class="form-control" v-model="selectionCriteria.batting_style_id">
                  <option value="">--Select--</option>
                  <option v-for="(battingStyle, index) in battingStyles" :key="index"
                          :value="battingStyle.id">
                    {{ battingStyle.name }}
                  </option>

                </select>
              </div>
            </div>

            <div class="col-6 col-md-3" v-if="selectionCriteria.playing_roll_id === 1">
              <div class="form-group">
                <label>Batting Order</label>
                <select class="form-control" v-model="selectionCriteria.batting_order_id">
                  <option value="">--Select--</option>
                  <option v-for="(battingOrder, index) in battingOrders" :key="index"
                          :value="battingOrder.id">
                    {{ battingOrder.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6 col-md-3" v-if="selectionCriteria.playing_roll_id === 2">
              <div class="form-group">
                <label>Bowling Style</label>
                <select class="form-control" v-model="selectionCriteria.bowling_style_id">
                  <option value="">--Select--</option>
                  <option v-for="(bowlingStyle, index) in bowlingStyles" :key="index"
                          :value="bowlingStyle.id">
                    {{ bowlingStyle.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-6 col-md-3" v-if="selectionCriteria.playing_roll_id === 2">
              <div class="form-group">
                <label>Bowling Type</label>
                <select class="form-control" v-model="selectionCriteria.bowling_type_id">
                  <option value="">--Select--</option>
                  <option v-for="(bowlingType, index) in bowlingTypes" :key="index"
                          :value="bowlingType.id">
                    {{ bowlingType.name }}
                  </option>
                </select>
              </div>
            </div>


          </div>
          <div class="row">
            <div class="col-12 col-md-8" v-if="tournaments.length > 0">
              <fieldset>
                <legend>
                  Tournaments
                </legend>
                <div class="row">
                  <div class="col-8">

                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <input type="text" class="form-control input-sm" v-model="tournamentName"
                             @keydown.enter="searchTournaments" placeholder="search" style="height: 30px;">
                    </div>
                  </div>
                </div>


                <table class="table table-bordered table-md">
                  <tbody>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                  <tr v-for="(t,index) in tournaments" :key="index">
                    <td>
                      <input type="radio" name="select_tournament" v-model="selectionCriteria.tournament_id"
                             :value="t.id">
                    </td>
                    <td>{{ t.name }}</td>
                    <td>{{ t.start_date }}</td>
                    <td>{{ t.end_date }}</td>
                  </tr>
                  </tbody>
                </table>

              </fieldset>


              <div class="text-center" v-if="tLoading">
                Loading...
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-info float-right btn-sm" @click="topPerformers">
                <i class="fa fa-download"></i> Fetch
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12">
      <div class="card card-primary">
        <div class="card-header d-inline-block">
          <h4 class="pr-0">
            <span v-if="selectionCriteria.playing_roll_id === 1"> Batting </span>
            <span v-if="selectionCriteria.playing_roll_id === 2"> Bowling </span>

            Report
            <router-link :to="{ name: 'report-top-performers-print' }" class="btn btn-info btn-sm float-right"
                         style="border-radius: 5px;" active-class="active" target="_blank">
              <i class="fa fa-print"></i> Print
            </router-link>

          </h4>


        </div>
        <div class="card-body">

          <transition enter-active-class="animate__animated animate__fadeIn">
            <BattingPerformance :player_data="player_data"
                                :performance="performance"
                                @orderBy="orderBy"
                                v-if="selectionCriteria.playing_roll_id ===  1"/>


          </transition>

          <transition enter-active-class="animate__animated animate__fadeIn">
            <BowlingPerformance :player_data="player_data"
                                :performance="performance"
                                @orderBy="orderBy"
                                v-if="selectionCriteria.playing_roll_id ===  2"/>
          </transition>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from '@/services/API';
import {useToast} from 'vue-toastification'
import BattingPerformance from "@/components/reports/performance/BattingPerformance";
import BowlingPerformance from "@/components/reports/performance/BowlingPerformance";
import {Performance} from "@/mixins/report/Performance";


export default {
  mixins: [Performance],

  data() {
    return {
      gameFormats: [],
      playingRolls: [],
      battingStyles: [],
      battingOrders: [],
      bowlingStyles: [],
      bowlingTypes: [],
      selectionCriteria: {},
      toast: useToast(),
      scores: [],
      playerInnings: [],
      player_data: [],
      players: [],
      playersToShow: [],
      hundred: 0,
      percent: [],
      cpercent: [],
      tournamentName: '',
      tournaments: [],
      tLoading: false,

      performance: [],
      countSort: 0,
    };
  },
  components: {
    BattingPerformance,
    BowlingPerformance
  },
  computed: {},

  methods: {

    orderBy(by) {
      this.countSort++;

      function sort(countSort) {
        return function (a, b) {


          if (by === 'id') {

            if (parseInt(a.id) < parseInt(b.id))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1

            if (parseInt(a.id) > parseInt(b.id))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1;

            return 0;

          }

          if (by === 'name') {

            if (a.en_first_name < b.en_first_name)
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (a.en_first_name > b.en_first_name)
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'mat') {

            if (parseInt(a.mat) < parseInt(b.mat))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.mat) > parseInt(b.mat))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'inns') {

            if (parseInt(a.inns) < parseInt(b.inns))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (a.inns > b.inns)
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'no') {

            if (parseInt(a.no) < parseInt(b.no))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.no) > parseInt(b.no))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'runs') {

            if (parseInt(a.runs) < parseInt(b.runs))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.runs) > parseInt(b.runs))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'hs') {

            if (parseInt(a.hs) < parseInt(b.hs))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.hs) > parseInt(b.hs))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'ave') {

            if (parseInt(a.ave) < parseInt(b.ave))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.ave) > parseInt(b.ave))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'bf') {

            if (parseInt(a.bf) < parseInt(b.bf))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.bf) > parseInt(b.bf))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'sr') {

            if (parseInt(a.sr) < parseInt(b.sr))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.sr) > parseInt(b.sr))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'hundred') {

            if (parseInt(a.hundred) < parseInt(b.hundred))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.hundred) > parseInt(b.hundred))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'fifty') {

            if (parseInt(a.fifty) < parseInt(b.fifty))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.fifty) > parseInt(b.fifty))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'four') {

            if (parseInt(a.four) < parseInt(b.four))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.four) > parseInt(b.four))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'six') {

            if (parseInt(a.six) < parseInt(b.six))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.six) > parseInt(b.six))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'ct') {

            if (parseInt(a.ct) < parseInt(b.ct))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.ct) > parseInt(b.ct))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'st') {

            if (parseInt(a.st) < parseInt(b.st))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.st) > parseInt(b.st))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'balls') {

            if (parseInt(a.balls) < parseInt(b.balls))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.balls) > parseInt(b.balls))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'wkts') {

            if (parseInt(a.wkts) < parseInt(b.wkts))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.wkts) > parseInt(b.wkts))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'ave') {

            if (parseInt(a.ave) < parseInt(b.ave))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.ave) > parseInt(b.ave))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }

          if (by === 'eco') {

            if (parseInt(a.eco) < parseInt(b.eco))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.eco) > parseInt(b.eco))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }
          if (by === 'sr') {

            if (parseInt(a.sr) < parseInt(b.sr))
              if (countSort % 2 !== 0)
                return -1;
              else
                return 1
            if (parseInt(a.sr) > parseInt(b.sr))
              if (countSort % 2 !== 0)
                return 1
              else
                return -1

            return 0;

          }


        }
      }

      this.performance.sort(sort(this.countSort));
    },

    topPerformers() {

      this.playersToShow = [];
      this.scores = [];
      this.toast.clear();
      this.toast.info('Processing...');

      if (this.selectionCriteria.playing_roll_id === 1) {

        API.post('report/batting', this.selectionCriteria).then(response => {

          this.player_data = response.data;
          this.toast.clear();

          this.performance = [];


          let players_data = this.player_data;
          let players = players_data.players;

          for (let i = 0; i < players.length; i++) {

            this.performance.push({
              id: players[i].id,
              photo: players[i].photo,
              en_first_name: players[i].en_first_name,
              en_last_name: players[i].en_last_name,
              mat: this.matches(players[i].id),
              inns: this.innings(players[i].id),
              no: this.innings(players[i].id) > 0 ? this.innings(players[i].id) - this.outs(players[i].id) > 0 ? this.innings(players[i].id) - this.outs(players[i].id) : '-' : '-',
              runs: this.runs(players[i].id),
              hs: this.highScores(players[i].id),
              ave: this.outs(players[i].id) > 0 ? Math.round(((this.runs(players[i].id) / this.outs(players[i].id)) + Number.EPSILON) * 100) / 100 : Math.round((this.runs(players[i].id) + Number.EPSILON) * 100) / 100 === 0 ? '-' : Math.round((this.runs(players[i].id) + Number.EPSILON) * 100) / 100,
              bf: this.bf(players[i].id),
              sr: Math.round((((this.runs(players[i].id) / this.bf(players[i].id)) * 100) + Number.EPSILON) * 100) / 100 > 0 ? Math.round((((this.runs(players[i].id) / this.bf(players[i].id)) * 100) + Number.EPSILON) * 100) / 100 : '-',
              hundred: this.fhundred(players[i].id),
              fifty: this.fifty(players[i].id),
              four: this.four(players[i].id),
              six: this.six(players[i].id),
              caught: this.caught(players[i].id),
              stump: this.stump(players[i].id),
            });


          }


          this.orderBy('runs');
          this.orderBy('runs');


        }, error => {
          this.toast.clear();
          this.toast.error(error.response.data.message);
        });
      }


      if (this.selectionCriteria.playing_roll_id === 2) {

        API.post('report/bowling', this.selectionCriteria).then(response => {

          this.performance = [];

          this.player_data = response.data;

          let players = this.player_data.players

          for (let i = 0; i < players.length; i++) {

            let wickets = this.countWicket(players[i].id);
            let runs = this.runs(players[i].id);
            let wkts = this.wickets(players[i].id);
            let balls = this.balls(players[i].id);

            this.performance.push({

              id: players[i].id,
              en_first_name: players[i].en_first_name,
              en_last_name: players[i].en_last_name,
              photo: players[i].photo,
              mat: this.matches(players[i].id),
              inns: this.innings(players[i].id),
              balls: balls,
              runs: runs,
              wkts: wkts,
              maxWktsInInning: this.maxWicketInInning(players[i].id).wicket,
              minRunsInInning: this.minRunsInInning(players[i].id, this.maxWicketInInning(players[i].id).inning_id),
              maxWktsInFixture: this.maxWicketInFixture(players[i].id).wicket,
              minRunsInFixture: this.minRunsInFixture(players[i].id, this.maxWicketInFixture(players[i].id).fixture_id),
              ave: wkts > 0 ? (runs / wkts).toFixed(2) : runs,
              eco: runs > 0 && balls > 0 ? (runs / (balls / 6)).toFixed(2) : '-',
              sr: balls > 0 && wkts > 0 ? (balls / wkts).toFixed(2) : '-',
              fourWicket: wickets.four_wicket > 0 ? wickets.four_wicket : '-',
              fiveWicket: wickets.five_wicket > 0 ? wickets.five_wicket : '-',
              tenWicket: wickets.ten_wicket > 0 ? wickets.ten_wicket : '-',
              fourteenWicket: wickets.fourteen_wicket > 0 ? wickets.fourteen_wicket : '-',

            });
          }

          this.orderBy('runs');

          this.toast.clear();

        });
      }


    },

    loadData() {
      this.toast.clear();
      this.toast.info('Fetching Meta Data');
      API.get('report/meta-data/load').then(response => {
        this.gameFormats = response.data.gameFormats;
        this.playingRolls = response.data.playingRolls;
        this.battingStyles = response.data.battingStyles;
        this.battingOrders = response.data.battingOrders;
        this.bowlingStyles = response.data.bowlingStyles;
        this.bowlingTypes = response.data.bowlingTypes;
        this.toast.clear();


      });
    },
    prChange() {

      if (this.selectionCriteria.playing_roll_id === 1) {
        delete this.selectionCriteria.bowling_style_id;
        delete this.selectionCriteria.bowling_type_id;
      } else {
        delete this.selectionCriteria.batting_style_id;
        delete this.selectionCriteria.batting_order_id;
      }
    },

    loadTournaments() {

      this.toast.info('Processing...');
      API.get('report/load-format-tournaments?game_format_id=' + this.selectionCriteria.game_format_id).then(response => {


        this.tournaments = response.data;
        this.tLoading = false;
        this.toast.clear();
        if (this.tournaments.length < 1) {
          this.toast.info('No Tournament Found')
        }

      }, error => {

        this.tLoading = false;
        this.toast.clear();
        this.toast.error(error.response.data.message);

      })
    },

    searchTournaments() {


      this.tLoading = true;


      this.toast.info('Processing...');

      API.get('report/search-format-tournaments?seek=' + this.tournamentName + '&game_format_id=' + this.selectionCriteria.game_format_id).then(response => {

        this.toast.clear();
        if (response.data.length > 0) {
          this.tournaments = response.data;
        } else {
          this.toast.info('No Tournament Found')
        }

        this.tLoading = false;

      }, error => {

        this.tLoading = false;

        this.toast.error(error.response.data.message);

      })
    }

  },
  mounted() {
    this.loadData();
  }

}
</script>
<style>

.bar {
  height: 30px;
  background-color: #045BA6;
  color: #ffffff;
  vertical-align: middle;
  display: inline-block;
  border-radius: 2px;
}


.name {
  /*width: 250px;*/
  font-size: 12px;
}

.name span {
  font-size: 10px;
}

.table tr {
  border-bottom: 1px solid lightgray;
}

.percent {
  font-size: 12px;

}

table tr td {
  page-break-inside: avoid !important;
}

@media print {
  .report-params {
    display: none;
  }


  thead tr {
    page-break-inside: avoid !important;
    page-break-before: always !important
  }

  thead {
    display: table-header-group !important
  }

  tfoot {
    display: table-footer-group !important
  }

  body {
    background-color: white;
  }

}


</style>