<template>
  <table class="table table-bordered font-12">
    <thead>

    <tr>
      <td>#</td>
      <td @click="orderBy('id')">
        ID
         <i class="fa fa-sort-down" v-if="sortBy === 'id' && countSort % 2 !== 0"></i>
         <i class="fa fa-sort-up" v-if="sortBy === 'id' && countSort % 2 === 0"></i>
      </td>
      <td>
        Photo
      </td>
      <td @click="orderBy('name')">
        Name
        <i class="fa fa-sort-down" v-if="sortBy === 'name' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'name' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('mat')">
        Mat
        <i class="fa fa-sort-down" v-if="sortBy === 'mat' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'mat' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('inns')">
        Inns
        <i class="fa fa-sort-down" v-if="sortBy === 'inns' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'inns' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('no')">
        NO
        <i class="fa fa-sort-down" v-if="sortBy === 'no' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'no' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('runs')">
        Runs
        <i class="fa fa-sort-down" v-if="sortBy === 'runs' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'runs' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('hs')">
        HS
        <i class="fa fa-sort-down" v-if="sortBy === 'hs' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'hs' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('ave')">
        AVE
        <i class="fa fa-sort-down" v-if="sortBy === 'ave' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'ave' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('bf')">
        BF
        <i class="fa fa-sort-down" v-if="sortBy === 'bf' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'bf' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('sr')">
        SR
        <i class="fa fa-sort-down" v-if="sortBy === 'sr' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'sr' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('hundred')">
        100
        <i class="fa fa-sort-down" v-if="sortBy === 'hundred' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'hundred' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('fifty')">
        50
        <i class="fa fa-sort-down" v-if="sortBy === 'fifty' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'fifty' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('four')">
        4s
        <i class="fa fa-sort-down" v-if="sortBy === 'four' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'four' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('six')">
        6s
        <i class="fa fa-sort-down" v-if="sortBy === 'six' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'six' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('ct')">
        Ct
        <i class="fa fa-sort-down" v-if="sortBy === 'ct' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'ct' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('st')">
        St
        <i class="fa fa-sort-down" v-if="sortBy === 'st' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'st' && countSort % 2 === 0"></i>
      </td>

    </tr>
    </thead>

    <tbody>


      <tr v-for="(player, index) in performance" :key="index">

      <td width="30">
        {{ index + 1 }}
      </td>

      <td style="width: 65px;">
        {{ "P-" + player.id }}
      </td>

      <td style="width: 50px;" class="text-center">
        <img :src="$store.state.Global.rootCDN + player.photo" alt="" class="m-1"
             style="height: 45px; width: 45px; border-radius: 50%;">
      </td>

      <td class="name">
        {{ player.en_first_name }}

      </td>

      <td>
        {{ player.mat }}
      </td>
      <td>
        {{ player.inns }}
      </td>

      <td>
        {{ player.no }}
      </td>

      <td>
        {{ player.runs }}
      </td>
      <td>
        {{ player.hs }}
      </td>

      <td>
        {{ player.ave }}
      </td>

      <td>
        {{ player.bf }}
      </td>
      <td>

        {{ player.sr }}

      </td>
      <td>
        {{ player.hundred }}
      </td>
      <td>
        {{ player.fifty }}
      </td>
      <td>
        {{ player.four }}
      </td>
      <td>
        {{ player.six }}
      </td>
      <td>
        {{ player.caught }}
      </td>
      <td>
        {{ player.stump }}
      </td>


    </tr>
    </tbody>
  </table>
</template>

<script>

import {Performance} from "@/mixins/report/Performance";

export default {
  props: ['performance'],
  mixins: [Performance],

  data(){
    return{
      sortBy: 'runs',
      countSort: 2,
    }
  },

  methods: {
    orderBy(by) {
      this.sortBy = by;
      this.countSort++;

      this.$emit('orderBy', by);
    },
  }
}

</script>
<style>


</style>