<template>
  <table class="table table-bordered font-12">
    <thead>
    <tr>
      <td>#</td>
      <td @click="orderBy('id')">
        ID
        <i class="fa fa-sort-down" v-if="sortBy === 'id' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'id' && countSort % 2 === 0"></i>
      </td>
      <td>
        Photo
      </td>
      <td @click="orderBy('name')">
        Name
        <i class="fa fa-sort-down" v-if="sortBy === 'name' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'name' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('mat')">
        Mat
        <i class="fa fa-sort-down" v-if="sortBy === 'mat' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'mat' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('inns')">
        Inns
        <i class="fa fa-sort-down" v-if="sortBy === 'inns' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'inns' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('balls')">
        Balls
        <i class="fa fa-sort-down" v-if="sortBy === 'balls' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'balls' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('runs')">
        Runs
        <i class="fa fa-sort-down" v-if="sortBy === 'runs' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'runs' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('wkts')">
        Wkts
        <i class="fa fa-sort-down" v-if="sortBy === 'wkts' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'wkts' && countSort % 2 === 0"></i>
      </td>
      <td>
        BBI
      </td>
      <td>
        BBM
      </td>
      <td @click="orderBy('ave')">
        AVE
        <i class="fa fa-sort-down" v-if="sortBy === 'ave' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'ave' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('eco')">
        Eco
        <i class="fa fa-sort-down" v-if="sortBy === 'eco' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'eco' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('sr')">
        SR
        <i class="fa fa-sort-down" v-if="sortBy === 'sr' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === 'sr' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('4w')">
        4w
        <i class="fa fa-sort-down" v-if="sortBy === '4w' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === '4w' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('5w')">
        5w
        <i class="fa fa-sort-down" v-if="sortBy === '5w' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === '5w' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('10')">
        10
        <i class="fa fa-sort-down" v-if="sortBy === '10' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === '10' && countSort % 2 === 0"></i>
      </td>
      <td @click="orderBy('14')">
        14
        <i class="fa fa-sort-down" v-if="sortBy === '14' && countSort % 2 !== 0"></i>
        <i class="fa fa-sort-up" v-if="sortBy === '14' && countSort % 2 === 0"></i>
      </td>

    </tr>
    </thead>

    <tbody>
    <tr v-for="(player, index) in performance" style="page-break-inside:avoid !important; page-break-before:auto !important" :key="index">

      <td width="30">
        {{ index + 1 }}
      </td>

      <td style="width: 65px;">
        {{ "P-" + player.id }}
      </td>

      <td style="width: 50px;" class="text-center">
        <img :src="$store.state.Global.rootCDN + player.photo" alt="" class="m-1"
             style="height: 45px; width: 45px; border-radius: 50%;">
      </td>

      <td class="name">
        {{ player.en_first_name }}

      </td>

      <td>
        {{ player.mat }}
      </td>
      <td>
        {{ player.inns }}
      </td>

      <td>
        {{ player.balls }}
      </td>

      <td>
        {{ player.runs }}
      </td>
      <td>
        {{ player.wkts }}
      </td>

      <td>
        {{ player.maxWktsInInning + ' / ' + player.minRunsInInning }}
      </td>

      <td>
        {{ player.maxWktsInFixture + ' / ' + player.minRunsInFixture }}
      </td>
      <td>
        {{ player.ave }}
      </td>
      <td>

        {{player.eco}}

      </td>
      <td>
        <span v-if="player.wkts > 0 && player.balls > 0">
          {{ (player.balls / player.wkts).toFixed(2) }}
        </span>
        <span v-else>
          -
        </span>

      </td>
      <td>
        {{ player.fourWicket }}
      </td>
      <td>
        {{ player.fiveWicket }}
      </td>
      <td>
        {{ player.tenWicket }}
      </td>
      <td>
        {{ player.fourteenWicket }}
      </td>


    </tr>
    </tbody>


  </table>
</template>

<script>

import {Performance} from "@/mixins/report/Performance";

export default {
  props: ['performance'],
  mixins: [Performance],

  data() {
    return {
      sortBy: 'runs',
      countSort: 2,
    }
  },

  methods: {
    orderBy(by) {
      this.sortBy = by;
      this.countSort++;

      this.$emit('orderBy', by);
    },
  }
}

</script>

<style scoped>




</style>